/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./src/styles/style.css")
// require("prism-themes/themes/prism-material-oceanic.css")
// require("prismjs/plugins/line-numbers/prism-line-numbers.css")

exports.onInitialClientRender = () => {
  require("typeface-playfair-display")
  require("typeface-poppins")
}
